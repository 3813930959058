<template>
  <v-dialog
    v-model="finalizeDialog.show"
    persistent
    max-width="460"
  >
    <template v-slot:activator="{}">
      <v-btn
        color="primary"
        class="white--text"
        :disabled="processing.finalize || isFinalized"
        @click="finalize"
        id="finalize-statement-button">
        <span style="text-transform: uppercase" v-if="isFinalized">{{
            filterMonthName
          }} FINALIZED</span>
        <span style="text-transform: uppercase" v-else>FINALIZE {{ filterMonthName }}</span>
      </v-btn>

      <finalize-success-model :showSuccess="showSuccessModal" @confirmed="showSuccessModal = false"
                              :filterDate="filterDate"/>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Finalize Report
      </v-card-title>
      <v-card-text v-if="finalizeDialog.version === 'unmatched'">
        You have commissions for {{ filterMonthName }} that have not been assigned to a
        producer.
      </v-card-text>
      <v-card-text v-if="finalizeDialog.version === 'standard'">
        Would you like to finalize all reports to permanently lock in commissions for
        {{ filterMonthName }}?
        <br/><br/> <strong>Once finalized, reports are no longer editable.</strong>
      </v-card-text>

      <v-card-text v-if="finalizeDialog.version === 'unapproved'">
        <div class="mt-4">
          <strong>Producers:</strong>

          <div class="producerListContainer">
          <table class="producerListTable">
            <tr v-for="producer in producers" :key="producer.value">
              <td>
                <router-link
                  class="ba-primary--text producerLink"
                  :to="{name:'commission-statement',params:{user: producer.id},query:{initialDate: filterDate}}">
                  {{ producer.first_name + ' ' + producer.last_name }}
                </router-link>
              </td>
              <td class="producerStatusColumn">
                <div v-if="producer.approved">
                  <v-icon class="ba-success--text" size="25">
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                  Approved
                </div>
                <div v-else>
                  <v-icon class="ba-danger--text" size="25">
                    {{ icons.mdiAlertCircle }}
                  </v-icon>
                  Not Approved
                </div>
              </td>
            </tr>
          </table>
          </div>
          <div class="mt-5">
            To approve a report, you must visit the producer's individual report page.
            <br/><br/>
            <strong>Once a pay period is finalized, reports are no longer editable.</strong>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="finalizeDialog.show = false;processing.finalize = false;"
        >
          Close
        </v-btn>

        <v-btn
          color="primary"
          text
          v-if="finalizeDialog.version === 'unmatched' && $route.name !== 'commission-unmatched'"
          :to="{name:'commission-unmatched', query:{ initialDate: filterDate }}"
        >
          Review
        </v-btn>
        <v-btn
          color="primary"
          text
          v-if="finalizeDialog.version === 'unmatched' && $route.name === 'commission-unmatched'"
          @click="finalizeDialog.show = false; processing.finalize = false;"
        >
          Review
        </v-btn>


        <v-btn
          color="primary"
          text
          @click="confirmFinalize"
          v-if="finalizeDialog.version === 'standard'"
          id="finalize-statement-confirm-button"
        >
          Finalize {{ filterMonthName }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import finalizeSuccessModel from "./FinalizeSuccessModal.vue"
import {mapGetters, mapMutations} from "vuex";
import {mdiAlertCircle, mdiCheckCircle} from "@mdi/js";
import * as EVENTS from "@/config/globals/trackingEvents";

export default {
  props: [
    'filterDate'
  ],
  components: {
    finalizeSuccessModel
  },
  data() {
    return {
      finalizeDialog: {
        show: false,
        version: null
      },
      unapprovedUsers: [],
      processing: {
        finalize: false
      },
      icons: {
        mdiAlertCircle,
        mdiCheckCircle
      },
      showSuccessModal: false
    }
  },
  methods: {
    finalize() {
      //check if there are unmatched commissions
      this.processing.finalize = true;

      this.$api.commissions.unmatched({
        filters: {
          date: this.filterDate
        }
      }).then(({data}) => {
        if (data.length) {
          this.finalizeDialog.version = 'unmatched';
          this.finalizeDialog.show = true;
        } else {
          this.$api.commissionStatements.users({
            date: this.filterDate
          }).then(({data}) => {
            let hasUnapprovedProducers = false;

            this.$_.forEach(data, (user) => {
              if (user.approved === false) {
                hasUnapprovedProducers = true;

                this.unapprovedUsers.push(user);
              }
            });

            if (hasUnapprovedProducers) {
              this.finalizeDialog.version = 'unapproved';
            } else {
              this.finalizeDialog.version = 'standard';
            }

            this.finalizeDialog.show = true;
          })
        }
      })
    },
    formatFilterMonth(itemDate) {
      return this.$moment(itemDate).format('MMMM')
    },
    confirmFinalize() {
      this.$api.commissionStatements.finalize({
        date: this.filterDate
      }).then(({data: {data}}) => {
        this.$emit('finalized');
        this.finalizeDialog.show = false;
        this.processing.finalize = false;
        this.showSuccessModal = true;

        this.$api.commissionStatements
          .users({
            date: this.filterDate,
          })
          .then(({data}) => {
            this.updateProducers(data);
          })

        this.$tracking.event(EVENTS.FINALIZED_COMMISSION_STATEMENT,{
          date: this.filterDate,
        })
      })
    },
    ...mapMutations({
      updateProducers: 'updateProducers',
    })
  },
  mounted() {

  },
  computed: {
    filterMonthName() {
      return this.formatFilterMonth(this.filterDate);
    },
    isFinalized() {
      return this.producers.some(function(producer) {
        return producer.finalized === true;
      })
    },
    ...mapGetters({
      userCommissionStatement: 'getCommissionStatement',
      producers: 'getProducers'
    }),
  },
  watch: {
    filterDate(value) {
      this.$debug.info('filterDate changed. ' + value);
    }
  }
}
</script>

<style lang="scss" scoped>
.producerListTable {
  width: 100%;
  margin-top: 15px;

  td {
    padding-bottom: 15px;
  }

  .producerLink {
    font-size: 16px;
    text-decoration: none;
  }

  .producerStatusColumn {
    width: 0.1%;
    white-space: nowrap;
  }
}

.producerListContainer{
  padding-left: 5px;
  padding-right:5px;
  max-height:350px;
  overflow-y: auto;
}
</style>

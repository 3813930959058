<template>
  <v-alert
    style="max-width:630px;"
    text
    type="error"
    :icon="icons.mdiAlertCircleOutline"
    dense
    v-if="unmatchedCount > 0"
    class="mt-5"
  >
    <v-row align="center">
      <v-col class="grow">
        You have unmatched commissions for this period.
      </v-col>
      <v-col class="shrink">
        <v-btn :to="{ name:'commission-unmatched',query:{ initialDate: this.date } }" color="red"
               class="white--text">Review
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import {mdiAlertCircleOutline} from '@mdi/js';

export default {
  props: ['date'],
  data() {
    return {
      icons: {
        mdiAlertCircleOutline
      },
      unmatchedCount: 0
    }
  },
  watch: {
    date() {
      if(this.date) {
        this.$api.commissions.unmatched({
          filters: {
            date: this.date
          }
        }).then(({data}) => {
          this.unmatchedCount = data.length;
        })
      }else{
        this.unmatchedCount = 0;
      }
    }
  }
}
</script>

<style scoped>

</style>

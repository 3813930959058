<template>
  <v-row no-gutters style="flex-wrap: nowrap">
    <v-col cols="1" class="" style="width: 15px" v-if="hasUnmatched">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="red--text mt-5 mr-3" v-bind="attrs" v-on="on">
            {{ icons.mdiAlertCircleOutline }}
          </v-icon>
        </template>
        <span>You have unmatched commissions.</span>
      </v-tooltip>
    </v-col>
    <v-col>
      <v-select
        hide-details
        :items="userFilterList"
        :prepend-icon="icons.mdiAccount"
        v-model="filterUser"
        :return-object="false"
        item-text="display"
        item-value="value"
        @change="changeUser"
        :disabled="loading.userList"
        :loading="loading.userList"
        class="mt-1"
      >
        <template v-slot:item="{ item }">
          <div :class="hasUnmatched && item.value === 'unmatched' ? 'red--text' : 'grey--text'">
            <span v-if="item.system !== true">
              <v-icon v-if="item.approved || item.finalized" class="user-status-icon" style="color: #009688">
                done_outline
              </v-icon>
              <v-icon v-else class="user-status-icon" color="secondary">
                brightness_1
              </v-icon>
            </span>

            <span v-if="item.value === 'unmatched' && hasUnmatched">
              <v-icon size="20" class="user-status-icon pb-1 red--text">
                {{ icons.mdiAlertCircleOutline }}
              </v-icon>
            </span>

            {{ item.display }}
          </div>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mdiAccount, mdiAlertCircleOutline } from '@mdi/js'
import {mapGetters, mapMutations} from "vuex";

export default {
  props: ['filterDate'],
  data() {
    return {
      filterUser: '',
      hasUnmatched: false,
      icons: {
        mdiAccount,
        mdiAlertCircleOutline,
      },
      loading: {
        userList: true,
      },
    }
  },
  methods: {
    getUsersList() {
      this.$api.commissionStatements
        .users({
          date: this.filterDate,
        })
        .then(({ data }) => {
          this.updateProducers(data);
          this.loading.userList = false
          this.setUserFromRoute()
        })

      this.$api.commissions
        .unmatched({
          filters: {
            date: this.filterDate,
          },
        })
        .then(({ data }) => {
          this.hasUnmatched = data.length > 0
        })
    },
    setUserFromRoute() {
      switch (this.$route.name) {
        case 'commission-report':
          this.filterUser = 'all'
          this.changeUser('all')
          break

        case 'commission-unmatched':
          this.filterUser = 'unmatched'
          this.changeUser('unmatched')
          break

        case 'commission-statement':
          this.filterUser = this.$route.params.user
          this.changeUser(this.$route.params.user)
          break
      }
    },
    changeUser(userId) {
      switch (userId) {
        case 'all':
          if (this.$route.name !== 'commission-report') {
            this.$router.push({ name: 'commission-report', query: {initialDate: this.filterDate} })
          }
          break
        case 'unmatched':
          if (this.$route.name !== 'commission-unmatched') {
            this.$router.push({ name: 'commission-unmatched', query: {initialDate: this.filterDate} })
          }
          break
        default:
          if (!this.filterUser.length && this.$route.name === 'commission-statement') {
            this.filterUser = this.$route.params.user
          }

          if (this.$route.name !== 'commission-statement') {
            this.$router.push({ name: 'commission-statement', params: { user: userId }, query: {initialDate: this.filterDate} })
          } else if (this.$route.params.user !== userId) {
            this.$emit('userChanged', userId)
          }

          break
      }
    },
    ...mapMutations({
      updateProducers: 'updateProducers',
    })
  },
  mounted() {
    this.getUsersList()
  },
  watch: {
    filterDate() {
      this.getUsersList()
    },
    $route() {
      this.setUserFromRoute()
    },
  },
  computed: {
    ...mapGetters({
      userFilterList: 'getUserFilterList',
      producers: 'getProducers'
    })
  },
}
</script>

<style scoped>
.user-status-icon {
  font-size: 9px;
  position: relative;
  left: -5px;
}
</style>

<template>
  <v-dialog
    v-model="successDialog.show"
    max-width="350"
  >
    <v-card>
      <v-card-text>
          <div class="text-center mb-3">
                <v-icon class="green--text mt-5" size="50">
                  {{ icons.mdiCheckCircleOutline }}
                </v-icon>
          </div>

        Your {{ filterMonthName }} commission statements have been finalized.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="$emit('confirmed')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheckCircleOutline } from "@mdi/js";

export default {
  props: [
    'filterDate',
    'showSuccess'
  ],
  data() {
    return {
      successDialog: {
        show: false,
      },
      icons:{
        mdiCheckCircleOutline
      }
    }
  },
  methods: {
    formatFilterMonth(itemDate) {
      return this.$moment(itemDate).format('MMMM')
    },
  },
  computed: {
    filterMonthName() {
      return this.formatFilterMonth(this.filterDate);
    }
  },
  mounted(){
    this.successDialog.show = this.showSuccess;
  },
  watch:{
    showSuccess(value){
        this.successDialog.show = value ? true : false
    }
  }
}
</script>

<style scoped>

</style>

let commissionReportTableHeaders = [
  {
    text: 'Effective',
    sortable: false,
    value: 'effective'
  },
  {
    text: 'Type',
    sortable: false,
    value: 'type'
  },
  {
    text: 'Insured',
    sortable: false,
    value: 'insured'
  },
  {
    text: 'Policy Number',
    sortable: false,
    value: 'policy_id'
  },
  {
    text: 'Carrier',
    sortable: false,
    value: 'carrier'
  },
  {
    text: 'Agency Pay',
    sortable: false,
    value: 'agency_pay'
  },
  {
    text: 'Producer',
    sortable: false,
    value: 'producer'
  },
  {
    text: '',
    value: 'data-table-expand',
  },
];

export default commissionReportTableHeaders;
